<template>
    <properties-block  v-bind="$props" v-on="$listeners">
        <properties-component-text title="Text|Texte" :value="block.text" v-bind="componentProps"/>
    </properties-block>
</template>

<script>
import PropertiesComponentButton from "@/components/editor/properties/components/properties-component-button.vue";
import PropertiesComponentText from "@/components/editor/properties/components/properties-component-text.vue";
import PropertiesBlock from "@/components/editor/properties/blocks/properties-block.vue";

// properties-block-text #hot-reload-debug
export default {
    name: `properties-block-text`,
    components: {PropertiesComponentButton, PropertiesComponentText, PropertiesBlock},
    extends: PropertiesBlock
}
</script>

<style lang="scss" scoped>
</style>
